import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useOffers } from "~/old-app/api/offer";
import NoData from "~/old-app/components/Cards/NoData";
import { OfferCollectionCard } from "~/old-app/components/Cards/OfferCollectionCard";
import DisplayCount from "~/old-app/components/Dropdowns/DisplayCountDropdown";
import Pagination from "~/old-app/components/Pagination/Pagination";
import Spinner from "~/old-app/components/Spinner";
import { PerPageOptions } from "~/old-app/constants";
import { useGoogleTag } from "~/old-app/hooks/useGoogleTag";
import { useConfigSlice } from "~/old-app/redux/config-slice";
import { GtmEvents } from "~/old-app/utils/helpers/google-tags";
export default function Offers({
  showCloseButton = false,
  onClose,
  onClickOffer,
}) {
  const { t } = useTranslation();

  const { selectedService } = useConfigSlice();
  const [limit, setLimit] = useState(50);

  const { offers, isLoading, page, setPage, hasMore, total } = useOffers({
    limit,
  });
  const { sendGoogleTagEvent } = useGoogleTag();
  useEffect(() => {
    sendGoogleTagEvent({
      event: GtmEvents.Offers,
      dataLayer: {},
    });
  }, []);

  if (isLoading || !selectedService) {
    return (
      <div className="flex w-full min-h-screen items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="container ">
      <div className="flex items-center justify-between">
        <h1
          className={`text-2xl text-black leading-3 h-7 capitalize overflow-hidden my-8`}
        >
          {t("offers.title")}
        </h1>

        <div className="flex items-center gap-5 justify-end">
          <DisplayCount
            limit={limit}
            setLimit={setLimit}
            options={PerPageOptions}
          />
          {/* <ViewType viewType={viewType} setViewType={setViewType} /> */}
          {showCloseButton && (
            <button
              onClick={onClose}
              className={`focus:outline-none focus:border-none hover:text-primary-500`}
            >
              <svg
                className={`fill-current text-black hover:text-primary-500`}
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 
          9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                ></path>
              </svg>
            </button>
          )}
        </div>
      </div>

      {!isLoading && offers.length === 0 && (
        <NoData
          title={t("offers.noOffers")}
          subtitle={t("offers.noOffersDescription")}
        />
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-10">
        {offers.map((offer) => (
          <OfferCollectionCard
            key={`offer-${offer.id}`}
            offer={offer}
            onClickOffer={onClickOffer}
          />
        ))}
      </div>

      {hasMore && (
        <Pagination
          itemsPerPage={limit}
          totalItems={total}
          paginate={setPage}
          table={true}
          currentPage={page}
        />
      )}
    </div>
  );
}
